import '../styles/Watch.scss';
import titleEpisodes from '../images/title-episodes.svg';
import { useEffect, useState } from 'react';
import Parser from 'rss-parser';
import { youTubeHotOnesPlaylistURL, youTubeHotOnesPlaylistRSS } from './_helpers';
import WalmartButtonHTML from './WalmartButtonHTML';

function Watch(props) {
    const [videos, setVideos] = useState([]);

    // get youtube RSS
    useEffect(() => {
        const parser = new Parser();
        const fetchVideos = async () => {
            const url = youTubeHotOnesPlaylistRSS;
            const feed = await parser.parseURL(url);
            const filteredVideos = [];

            for (let i = 0; i < feed.items.length; i++) {
                if (i <= 3) {
                    filteredVideos.push(
                        <a className="video-yt-url" href={feed.items[i].link} target="_blank" rel="noreferrer" key={feed.items[i].title}>
                            <img className="video-yt-image" src={`https://i4.ytimg.com/vi/${feed.items[i].id.replace('yt:video:', '')}/hqdefault.jpg`} alt={feed.items[i].title} />
                            <p className="video-yt-title">{feed.items[i].title}</p>
                        </a>
                    );
                } else {
                    break;
                }
            }

            setVideos(filteredVideos);
        }

        fetchVideos();
    }, []);

    return (
        <div className="watch">
            <div className="limit limit--heading">

                {/* <img src={titleWatch} className="section-title watch-title" alt="watch the spiciest interview show on the internet" /> */}
                <picture className="buy-heading">
                    <source media="(min-width: 641px)" srcSet="https://bin.complex.com/websites/hot-ones-challenge/refresh/watch-desktop.svg" />
                    <img src='https://bin.complex.com/websites/hot-ones-challenge/refresh/watch-mobile.svg' alt='watch the spiciest interview show on the internet' />
                </picture>

                <p className="watch-body-copy body-copy">Hot Ones is the show with hot questions and even hotter wings! Catch up on the latest season of interviews with your favorite celebrities.</p>
            </div>

            <div className="latest-episodes-wrap">
                <div className="limit">
                    <img className="episodes-title" src={titleEpisodes} alt="latest episodes" />
                </div>

                <div className="limit limit--youtube-videos">
                    <div className="youtube-videos-wrap">
                        {videos}
                    </div>
                </div>

                <div className="limit">
                    <a className="pill-button-watch" href={youTubeHotOnesPlaylistURL} rel="noreferrer" target="_blank">
                        WATCH <span>ALL EPISODES</span>
                    </a>
                </div>
            </div>

            <WalmartButtonHTML className="watch-walmart-button" openModal={props.openModal} />

            <div className="border"></div>
        </div>
    );
}

export default Watch;
