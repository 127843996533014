function Snowflakes() {
  return (
    <>
      {Array.from({ length: 100 }).map((_, index) => (
        <div key={`${index}`} className="snow"></div>
      ))}
    </>
  )
}

export default Snowflakes;
