import '../styles/Footer.scss';

function Footer() {
    return (
        <div className="footer">
            <div className="legal">
                <a href="https://www.complex.com/terms" rel="noreferrer" target="_blank">Terms of Use</a>

                <a href="https://www.complex.com/privacy" rel="noreferrer" target="_blank">Privacy Policy</a>

                <button id="ot-sdk-btn" className="ot-default ot-sdk-show-settings">COOKIES SETTINGS</button>
            </div>

            <div className="copyright">
                <p>&copy; First We Feast/Complex Networks. All rights Reserved.</p>
            </div>
        </div>
    );
}

export default Footer;
