import { useEffect, useState } from 'react';

import '../styles/Intro.scss';
import WalmartButtonHTML from './WalmartButtonHTML';
import hotones from '../images/hotoneschallenge.svg';
import hotonesdescription from '../images/hotoneschallencedescription.svg';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

// import required modules
import { Navigation } from "swiper";

const imgCDNUrl = 'https://bin.complex.com/websites/hot-ones-challenge/img/';

const slideImgs = [
    'package-01-hot-ones-los-calientes-smoky-sweet-chicken-bites.png',
    'package-02-hot-ones-los-calientes-smoky-habanero-chicken-bites.png',
    'package-03-hot-ones-los-calientes-barbacoa-chicken-bites.png',
    'package-04-hot-ones-classic-garlic-fresno-chicken-bites.png',
    'package-05-hot-ones-classic-chicken-bites.png',
];

const additionalSlideImgs = [
    'https://bin.complex.com/websites/hot-ones-challenge/refresh/HotOnes_SmokyHabanero_ChickenStrips_22.5oz_1000px.png',
    'https://bin.complex.com/websites/hot-ones-challenge/refresh/HotOnes_SpicyGarlic_ChickenStrips_22.5oz_1000px.png',
];

const navigation = {
    enabled: true,
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
};

const images = {
    0: 'https://bin.complex.com/websites/hot-ones-challenge/refresh/P03A%20_Hot-Ones-Family-Shot_Shadow.png',
    1: 'https://bin.complex.com/websites/hot-ones-challenge/refresh/P06_Hot-Ones-Family-Shot_Shadow.png',
};

function Intro(props) {
    const [image, setImage] = useState(images['0']);

    useEffect(() => {
        (async function() {
            await new Promise((resolve) => {
                setTimeout(() => {
                    setImage(image === images['0'] ? images['1'] : images['0']);
                    resolve();
                }, 5000);
            })
        })();
    }, [image]);

    return (
        <div className="intro">
            <div className="header">
                <img className="logo" src="https://bin.complex.com/websites/hot-ones-challenge/img/logo-hot-ones-challenge-at-home.png" alt="hot ones challenge at home logo" />
            </div>

            <div className="row--1">
                <img className="intro-heading" src="https://bin.complex.com/websites/hot-ones-challenge/refresh/title.png" alt="bring the heat home" />

                <div className={`intro-product intro-product-strip-bags ${image === images['0'] ? 'active-image': 'disabled-image'}`}>
                    <img src={images['0']} alt="Hot Ones family panorama shot" />
                    <p>Try our new Spicy Garlic and Smoky Habanero Chicken Strips </p>
                </div>
                <div className={`intro-product intro-product-chicken-bites ${image === images['1'] ? 'active-image': 'disabled-image'}`}>
                    <img src={images['1']} alt="Hot Ones family panorama shot" />
                    <p>Dig into our boneless chicken bites</p>
                </div>
            </div>

            <div className='row--2'>
                <div className="limit">
                    <div className='container-walmart-button'>
                        <WalmartButtonHTML className="intro-walmart-button" openModal={props.openModal}/>
                    </div>

                    <div className='container-intro-hashtag'>
                        <a className="intro-hashtag" href="#challenge" rel="noreferrer">
                            <img className='hashtag-title' src={hotones} alt='hot ones challenge title' />
                            <img className='hashtag-description' src={hotonesdescription} alt='Play at home and use the hashtag to heat things up on social media.' />
                        </a>
                    </div>

                    <div className='container-intro-scroll'>
                        <div className="intro-scroll">
                            <p className="intro-scroll-title title--style1">Scroll down for more</p>
                            <img className="intro-arrows" src="https://bin.complex.com/websites/hot-ones-challenge/img/chevrons-down-white.png" alt="pre-order on walmart.com" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row--3'>
                <picture className='heading-packages'>
                    <source media="(min-width: 641px)" srcSet="https://bin.complex.com/websites/hot-ones-challenge/refresh/bring-hot-ones.png" />
                    <img src='https://bin.complex.com/websites/hot-ones-challenge/refresh/bring-hot-ones.png' alt='five new ways to bring hot ones home heading' />
                </picture>
            </div>

            <div className='row--4'>
                <div className="limit">
                    <div className='container-package-swiper'>
                        <div className="package-swiper-wrapper">
                            <Swiper
                                className="package-swiper"
                                modules={[Navigation]}
                                navigation={navigation}
                                slidesPerView={1}
                            >
                                {additionalSlideImgs.map((src, i) => {
                                    return (
                                        <SwiperSlide key={`new-image-${i}`}>
                                            <img className="package-item" src={src} alt={src.replace(/-/g, ' ').replace(/\.png$/g, '')} />
                                        </SwiperSlide>)
                                    }
                                )}
                                {slideImgs.map((src, i) => {
                                    if(i === 0){
                                        return (<SwiperSlide key={i}>
                                            <a href='https://www.samsclub.com/p/hot-ones-boneless-chicken-bites-challenge-pack-41-oz/P03013888?xid=plp_product_2' target='_blank' rel="noreferrer"><img className="package-item" src={imgCDNUrl + src} alt={src.replace(/-/g, ' ').replace(/\.png$/g, '')} /></a>
                                    </SwiperSlide>)
                                    } else {
                                    return (<SwiperSlide key={i}>
                                        <img className="package-item" src={imgCDNUrl + src} alt={src.replace(/-/g, ' ').replace(/\.png$/g, '')} />
                                    </SwiperSlide>)
                                };
                                })}
                            </Swiper>

                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                        </div>
                    </div>

                    <div className='container-product-info'>
                        <img className="intro-product-info" src="https://bin.complex.com/websites/hot-ones-challenge/refresh/brand-copy.svg" alt="the official heat of hot ones" />
                    </div>

                    <div className='container-badge'>
                        <div className="badge-holder">
                            <img className="intro-badge-empty" src="https://bin.complex.com/websites/hot-ones-challenge/img/badge-empty.svg" alt="badge background" />
                            <img className="intro-badge-text" src="https://bin.complex.com/websites/hot-ones-challenge/refresh/badge-text.svg" alt="includes official sauce packets" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row--5'>
                <div className="intro-scroll">
                    <p className="intro-scroll-title title--style2">Continue to scroll for instructions<br />&amp; more of Hot Ones!</p>
                    <img className="intro-arrows" src="https://bin.complex.com/websites/hot-ones-challenge/img/chevrons-down-white.png" alt="pre-order on walmart.com" />
                </div>
            </div>
        </div>
    );
}

export default Intro;
