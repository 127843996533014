import '../../styles/HolidayPartyPack.scss';
import '../../styles/App.scss';
import howToEnjoy from '../../images/holiday-howtoenjoy.svg';
import logoSeal from '../../images/holiday-logo-seal.svg';
import box1 from '../../images/box-1.png';
import box2 from '../../images/box-2.png';
import box3 from '../../images/box-3.png';
import box4 from '../../images/box-4.png';
import box5 from '../../images/box-5.svg';
import box6 from '../../images/box-6.png';
import box7 from '../../images/box-7.png';
import box8 from '../../images/box-8.png';


function PartyPack() {
  return (
    <div className="holiday-party-pack">
      <div className="holiday-container">
        <div className="holiday-pack-heading">
          <img src={howToEnjoy} alt="holiday how to enjoy" />
          <p>A few creative ideas to get you started</p>
        </div>
        <div className="holiday-pack-content">
          <div className="holiday-pack-content__row-1">
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-1">
              <img src={box1} alt="holiday pack image box 1" />
            </div>
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-2">
              <img src={box2} alt="holiday pack image box 2" />
            </div>
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-3">
              <img src={box3} alt="holiday pack image box 3" />
            </div>
          </div>
          <div className="holiday-pack-content__row-2">
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-4">
              <img src={box4} alt="holiday pack image box 4" />
            </div>
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-5">
              <img src={box5} alt="holiday pack image box 5" />
            </div>
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-6">
              <img src={box6} alt="holiday pack image box 6" />
            </div>
          </div>
          <div className="holiday-pack-content__row-3">
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-7">
              <img src={box7} alt="holiday pack image box 7" />
            </div>
            <div className="holiday-pack-image-wrapper holiday-pack-image-wrapper--image-8">
              <img src={box8} alt="holiday pack image box 8" />
            </div>
          </div>
        </div>
        <div className="holiday-intro-scroll">
          <div className="holiday-separator-wrapper-with-chicken">
            <img className="holiday-logo-seal" src={logoSeal} alt="logo seal" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartyPack;
