import '../../styles/HolidayStoreFinder.scss';
import '../../styles/App.scss';
import storeFinder from '../../images/holiday-storefinder.svg';
import logoSeal from '../../images/holiday-logo-seal.svg';


function StoreFinder() {
  return (
    <div id="holiday-store-finder" className="holiday-store-finder">
      <div className="holiday-container">
        <div className="holiday-store-finder-heading">
          <img src={storeFinder} alt="holiday store finder" />
          <p>Enter your location to find a Sam's Club store near you</p>
        </div>
        <iframe id="holiday-map" src="https://bin.complex.com/websites/hot-ones-challenge/holiday/index.html" className="holiday-map"></iframe>
        <div className="holiday-intro-scroll">
          <div className="holiday-separator-wrapper-with-chicken">
            <img className="holiday-logo-seal" src={logoSeal} alt="logo seal" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreFinder;
