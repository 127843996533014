import '../styles/Challenge.scss';
import titleChallenge from '../images/title-challenge.svg';
import ahc from '../images/ahc.svg';
import { instaChallengeURL } from './_helpers';

function Challenge() {
    return (
        <div className="challenge" id="challenge">
            <div className="limit">
                <img src={ahc} className="ahc" alt="at home challenge" />

                <img src={titleChallenge} className="section-title challenge-title" alt="hot ones challenge" />

                <p className="challenge-body-copy body-copy">Play the <a href={instaChallengeURL} rel="noreferrer" target="_blank">#HotOnesChallenge</a> at home with our new Hot Ones Boneless Chicken Bites. The next time you bring the heat to game night (or the dinner table), hit record, snap that pic, and share all the spicy reactions with us for a chance to be featured on First We Feast! Just use <a href={instaChallengeURL} rel="noreferrer" target="_blank">#HotOnesChallenge</a> to heat things up on social media. </p>
            </div>

            <div className="challenge-insta-wrap">
                <div className="limit">
                    <div className="elfsight-app-372edd79-970f-4aa7-98a5-85a1b003dc90"></div>
                </div>
            </div>

            <div className="limit limit--hashtag">
                <p className="challenge-hashtag">
                    <a href={instaChallengeURL} rel="noreferrer" target="_blank">
                        #<span>HOTONES</span>CHALLENGE
                    </a>
                </p>
            </div>
        </div>
    );
}

export default Challenge;
