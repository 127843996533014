import '../styles/WalmartButtonHTML.scss';
// import { walmartURL } from './_helpers';

function WalmartButtonHTML(props) {
    return (
        // <a className={`walmart-button-html ${props.className}`} href={walmartURL} rel="noreferrer" target="_blank">
        <div className={`walmart-button-html ${props.className}`} onClick={props.openModal}>
            <p className="walmart-button-title">FIND YOUR</p>
            <p className="walmart-button-title">NEAREST STORE</p>
            {/* <p className="walmart-button-sub-title">IN STORES &amp; ONLINE</p> */}
        </div>
        // </a>
    );
}

export default WalmartButtonHTML;
