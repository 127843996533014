import '../../styles/HolidayIntro.scss';
import '../../styles/App.scss';
import happyHolidays from '../../images/happy-holidays.svg';
import holidayPack from '../../images/holiday-pack.png';
import introPartyPack from '../../images/intro-party-pack.svg';
import '../../styles/App.scss';

function HolidayIntro() {
  const scrollToMap = () => {
    document.getElementById('holiday-store-finder').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="holiday-intro">
      <div className="holiday-container">
        <div className="header">
          <img className="logo" src={introPartyPack} alt="hot ones challenge party pack" />
        </div>
        <div className="happy-holidays-heading-row">
          <img className="happy-holidays-heading" src={happyHolidays} alt="happy holidays" />
        </div>
        <div className="holiday-pack-row">
          <span className="holiday-pack-copy">The fan-favorite <span className="holiday-pack-copy-red">Holiday Party Pack</span> is the perfect gift for all the Spice Lords out there! </span>
          <img className="holiday-pack-image" src={holidayPack} alt="holiday pack" />
          <div className="holiday-badge">
            <div className="holiday-badge-holder" onClick={scrollToMap}>
              <img className="holiday-intro-badge-empty" src="https://bin.complex.com/websites/hot-ones-challenge/img/badge-empty.svg" alt="badge background" />
              <img className="holiday-intro-badge-text" src="https://bin.complex.com/websites/hot-ones-challenge/refresh/badge-text.svg" alt="includes official sauce packets" />
            </div>
          </div>
        </div>
        <div className="holiday-intro-scroll">
          <p className="holiday-scroll-title">Scroll down for more!</p>
          <div className="holiday-separator-wrapper">
            <img className="holiday-intro-arrows" src="https://bin.complex.com/websites/hot-ones-challenge/img/chevrons-down-white.png" alt="chevrons" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HolidayIntro;
