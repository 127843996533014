import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation } from 'swiper';

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

import '../../styles/HolidaySlider.scss';
import '../../styles/App.scss';
import truthOrDab from '../../images/holiday-truthofdab.svg';
import gameImage1 from '../../images/game-card-1.png';
import gameImage2 from '../../images/game-card-2.png';
import gameImage3 from '../../images/game-card-3.png';
import gameImage4 from '../../images/game-card-4.png';
import gameImage5 from '../../images/game-card-5.png';
import logoSeal from '../../images/holiday-logo-seal.svg';

const images = [
  gameImage1,
  gameImage2,
  gameImage3,
  gameImage4,
  gameImage5,
];

const navigation = {
  enabled: true,
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev'
};


function Slider() {
  return (
    <div className="holiday-slider">
      <div className="holiday-container">
        <div className="holiday-slider-heading">
          <img src={truthOrDab} alt="truth or dab game" />
          <p>Your favorite game with new questions now!</p>
        </div>
        <div className="holiday-slider-wrapper">
          <Swiper
            centeredSlides
            modules={[Navigation]}
            loop
            navigation={navigation}
            className="holiday-swiper"
            breakpoints={{
              599: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              641: {
                slidesPerView: 3,
                spaceBetween: 20,
              }
            }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} className="holiday-swiper-slide">
                {({ isActive }) => {
                  return (
                    <img className={`slider-image ${isActive ? 'slider-image-active' : 'slider-image-blur'}`} src={image} alt={`game image ${index}`} />
                  )
                }}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
        <div className="holiday-intro-scroll">
          <div className="holiday-separator-wrapper-with-chicken">
            <img className="holiday-logo-seal" src={logoSeal} alt="logo seal" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider;
