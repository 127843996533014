import { useRef, useState, useEffect } from 'react';
import { videoURL, videoPosterURL } from './_helpers';

function VideoPlayer() {
  const [playButtonDisplay, setPlayButtonDisplay] = useState("block");
  const videoPlayer = useRef();


  function handlePlayIconClick() {
    videoPlayer.current.play();
    hidePlayIcon();
  }

  function hidePlayIcon() {
    setPlayButtonDisplay('none');

  }
  function showPlayIcon() {
    setPlayButtonDisplay('block');
  }

  useEffect(() => {
    if (videoPlayer) {
        const currentVideoPlayer = videoPlayer.current;
        currentVideoPlayer.addEventListener('play', hidePlayIcon);
        currentVideoPlayer.addEventListener('pause', showPlayIcon);

        return () => {
            currentVideoPlayer.removeEventListener('play', hidePlayIcon);
            currentVideoPlayer.removeEventListener('pause', showPlayIcon);
        }
    }
  }, [videoPlayer]);

  return (
    <div>
      <video
        ref={videoPlayer}
        src={videoURL}
        poster={videoPosterURL}
        controls
        playsInline></video>

      <svg className="video-play-icon" onClick={handlePlayIconClick} style={{ display: playButtonDisplay }} width="126" height="126" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter x="-3.3%" y="-3.3%" width="106.7%" height="106.7%" filterUnits="objectBoundingBox" id="a">
            <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.204467773 0" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g filter="url(#a)" transform="translate(3 3)" fill="none" fillRule="evenodd">
          <path d="M60 0c33.137 0 60 26.863 60 60s-26.863 60-60 60S0 93.137 0 60 26.863 0 60 0zM43 40v40l40-20-40-20z" fill="#E0301A" opacity=".5" />
          <circle stroke="#F3E227" strokeWidth="10" cx="60" cy="60" r="55" />
        </g>
      </svg>

    </div>
  );
}

export default VideoPlayer;
