import { useState } from 'react';
import '../styles/App.scss';
import '../styles/OneTrust.scss';
import Intro from './Intro';
import Video from './Video';
import Challenge from './Challenge';
import Watch from './Watch';
import Buy from './Buy';
import Footer from './Footer';
import Modal from 'react-modal';

const iframeSrc = 'https://wtb.bio/map/johnsoulesfoods?embed=1&src=https%3A%2F%2Fwww.johnsoulesfoods.com%2Fwhere-to-buy%2F%3Fwbprod%3D16725%2C16724%2C16091%2C7604%2C10278%2C10279%2C10280%2C10281%2C10282&wbprod=16725,16724,16091,7604,10278,10279,10280,10281,10282&minH=625';
const iframeStyles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 11,
    background: 'linear-gradient(to bottom, #f2d726, #e0301a)'
  },
  content: {
    inset: '15px',
    padding: '15px',
    overflow: 'initial'
  }
};

Modal.setAppElement('#root');


function Home() {
  const [modalIsOpen, setIsOpen] = useState(false);
    
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
      <div id="home">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Walmart Srores Finder Modal">
            <button className="modal__close-button" onClick={closeModal}>close</button>
            <iframe
              id="_zunlguvh4"
              title='Walmart Srores Finder'
              style={iframeStyles}
              frameBorder="0"
              scrolling="no"
              allow="geolocation"
              src={iframeSrc}></iframe>
        </Modal>
        <Intro openModal={openModal} />
        <Video openModal={openModal} />
        <Buy openModal={openModal} />
        <Watch openModal={openModal} />
        <div id="watch-and-buy-bg">
            <Challenge openModal={openModal} />
        </div>
        <Footer />
      </div>
    );
}

export default Home;
