import HolidayIntro from './Intro';
import PartyPack from './PartyPack';
import StoreFinder from './StoreFinder';
import Slider from './Slider';
import HolidayBuy from './Buy';
import Footer from '../Footer';
import Snowflakes from './Snowflakes';
import '../../styles/App.scss';


function Holiday() {
  return (
    <div id="holiday" className="holiday">
      <div className="holiday-snowflakes">
        <Snowflakes />
      </div>
      <HolidayIntro />
      <PartyPack />
      <StoreFinder />
      <Slider />
      <div className="holiday-footer">
        <HolidayBuy />
        <Footer />
      </div>
    </div>
  )
}

export default Holiday;
