import '../styles/Buy.scss';
import {
    // walmartURL,
    // truthOrDabGameURL,
    heatonistURL,
    hotOnesJrSauceURL,
    amazonURL
} from './_helpers';
import ahc from '../images/ahc.svg';

function Buy(props) {
    return (
        <div className="buy">
            <div className="limit">
                <img src={ahc} className="ahc ahc-buy" alt="at home challenge" />
                <picture className="buy-heading">
                    <source media="(min-width: 641px)" srcSet="https://bin.complex.com/websites/hot-ones-challenge/refresh/shop-desktop.svg" />
                    <img src='https://bin.complex.com/websites/hot-ones-challenge/refresh/shop-mobile.svg' alt='shop hot ones heading' />
                </picture>
            </div>

            <div className="limit limit--products">
                <div className="buy-item">
                    <div className="buy-chicken-link" onClick={props.openModal}>
                        <img className="buy-image" src="https://bin.complex.com/websites/hot-ones-challenge/img/buy-slide-01.jpg" alt="buy-slide-01" />

                        <p className="buy-cta">Available in stores<br /> &nbsp;</p>
                    </div>

                    <div className="pill-button-small pill-button-style1" onClick={props.openModal}>
                       FIND YOUR NEAREST STORE
                    </div>
                </div>

                <div className="buy-item">
                    <a className="buy-chicken-link" href={heatonistURL} rel="noreferrer" target="_blank">
                        <img className="buy-image" src="https://bin.complex.com/websites/hot-ones-challenge/img/buy-slide-02.jpg" alt="boneless chicken bites" />
                        <p className="buy-cta">Re-order sauces for your next<br />Truth or Dab Challenge</p>
                    </a>

                    <a  className="pill-button-small pill-button-style2"
                        href={heatonistURL}
                        target="_blank"
                        rel="noreferrer">
                        <span>BUY ON</span> HEATONIST
                    </a>
                </div>

                <div className="buy-item">
                    <a className="buy-sauce-link" href={hotOnesJrSauceURL} rel="noreferrer" target="_blank">
                        <img className="buy-image" src="https://bin.complex.com/websites/hot-ones-challenge/img/buy-slide-03.jpg" alt="three hot sauces" />
                        <p className="buy-cta">First-ever hot sauce for kids!<br />Make every meal an adventure!</p>
                    </a>

                    <a  className="pill-button-small pill-button-style2"
                        href={hotOnesJrSauceURL}
                        target="_blank"
                        rel="noreferrer">
                        <span>BUY ON</span> HEATONIST
                    </a>
                </div>

                <div className="buy-item">
                    <a className="buy-sauce-link" href={amazonURL} rel="noreferrer" target="_blank">
                        <img className="buy-image" src="https://bin.complex.com/websites/hot-ones-challenge/img/buy-slide-04.jpg" alt="three hot sauces" />

                        <p className="buy-cta">Get the Hot Ones Truth or Dab The Game.<br /> Hot Sauce Included</p>
                    </a>

                    <a className="pill-button-small pill-button-style2" href={amazonURL} rel="noreferrer" target="_blank">
                        <span>BUY ON </span> AMAZON.COM
                    </a>
                </div>
            </div>
            <div className="border" />
        </div>
    );
}

export default Buy;
