import '../styles/Video.scss';
import WalmartButtonHTML from './WalmartButtonHTML';
import VideoPlayer from './VideoPlayer';
import titleSpiceUp from '../images/title-spice-up.svg';
import ahc from '../images/ahc.svg';
import chicken from '../images/chicken.svg';

function Video(props) {
    return (
        <div className="video">
            <div className="limit">
                <img src={ahc} className="ahc" alt="at home challenge" />

                <img src={titleSpiceUp} className="section-title video-title" alt="spice up your game night" />

                <p className="video-body-copy body-copy">Put your friends and family in the hot seat! <br />Play a game of truth or dare with the heat turned up!< br />If someone refuses to answer, the dare is a one-way ticket to spicy town!</p>
            </div>

            <div className="limit">
                <div className="video-wrap">
                    <div className="video-wrap-inner">
                        <div className="video-holder">
                            <VideoPlayer />
                        </div>
                    </div>
                </div>

                <img className="video-dab-chicken" src={chicken} alt="last dab chicken logo" />
            </div>
                
            <WalmartButtonHTML className="video-walmart-button" openModal={props.openModal}/>

            <div className="border"></div>
        </div>
    );
}

export default Video;
