import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Holiday from './Holiday/Index';

function App() {
  return (
    <div id="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/holiday" element={<Holiday />} />
      </Routes>
    </div>
  );
}

export default App;
